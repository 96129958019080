@import '../vendors/bootstrap/functions';
@import '../vendors/bootstrap/mixins';
@import '../variables';
@import '../vendors/bootstrap/variables';

.columns .column.main {
  padding-bottom: 0;
}

.row-full-width-inner.row-full-width-inner.row-full-width-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  max-width: 1570px;
  height: 740px;
  padding-inline: 2.6rem;
  background-color: $dark;
  color: $white;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    height: 711px;
    font-size: 2.5rem;
  }

  &::before,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
  }

  &::before {
    content: '';
    z-index: -1;
    background-color: rgba($black, 0.12);
  }

  img {
    z-index: -2;
    object-fit: cover;
  }

  h1 {
    margin-bottom: 1rem;
    font-size: 4.5rem;
    color: inherit;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-bottom: 0.2rem;
      font-size: 8.7rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 5rem;
      line-height: 1.15;
    }
  }

  p {
    max-width: 268px;
    margin-inline: auto;

    @include media-breakpoint-up(md) {
      max-width: 456px;
    }
  }

  .btn {
    width: 163px;
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 1.5rem;
    }
  }
}
